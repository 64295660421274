import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StudentCarousel from "components/StudentCarousel";

import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { Col, FloatButton, Modal, Row, Select, Table, Image, Tooltip, message, Alert, Button, Drawer, Form, Input } from "antd";

import PropTypes from "prop-types";
import dayjs from "dayjs";
import locale from "antd/locale/fa_IR";
// import "dayjs/locale/fa_IR";
// import UploadDocuments from "components/UploadDocuments";
import { BookOutlined, CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, CloseOutlined, AppstoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

const Component = ({
	data = {},
	onUpdate,
	options: { classes = [] },
	fetchData,
	//   students
}) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {
		selected: [],
		student: [],
		studentsList: [],
		cancel: false,
		browed: false,
		borrowTime: "",
		unBorrowTime: "",
	};
	const [state, setState] = useState(initialState);
	const { submitting, visible, cancel, studentsList } = state;
	const { id } = data;
	const { classId, student, selected, browed, borrowTime, unBorrowTime } = state;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	var d = new Date(2018, 8, 18);

	const handleStudentFilter = (value) => {
		setState((prev) => ({
			...prev,
			classId: value,
		}));
	};
	const unborrowHandler = async (pixelStudent) => {
		const param = {
			pixel: data.id,
			pixelStudent: pixelStudent,
		};
		try {
			if (data.id) {
				await api.pixel.unborrow(param);
				setState((prev) => ({
					...prev,
					cancel: true,
				}));
				// window.location.reload();
				componentDidUpdate();
			}
		} catch (err) {
			console.log("ERROR-unBorrow", err);
		}
	};

	const borrowHandler = () => {
		if (data.id && state.selected) {
			const params = {
				student: selected,
				start: borrowTime,
				end: unBorrowTime,
			};
			api.pixel
				.borrow(data?.id, params)
				.then((res) => {
					setState((prev) => ({
						...prev,
						browed: true,
						borrowTime: "",
						unBorrowTime: "",
					}));
					componentDidUpdate();
				})
				.catch((err) => {
					setState((prev) => ({
						...prev,
						browed: true,
						borrowTime: "",
						unBorrowTime: "",
					}));
				});
		}
	};
	const selectedStudentHandler = (value) => {
		setState((prev) => ({
			...prev,
			selected: value,
		}));
	};

	const borrowTimeHandler = (value) => {
		console.log("value day", value);

		const date = value.$D < 10 ? `0${value.$D}` : value.$D;
		console.log("date day", date);

		console.log("month month", typeof value.$M);
		const month = value.$M < 9 ? `0${value.$M + 1}` : value.$M + 1;
		const formattedDate = `${value.$y}-${month}-${date}`;

		setState((prev) => ({
			...prev,
			borrowTime: formattedDate,
		}));
	};

	const unBorrowTimeHandler = (value) => {
		const date = value.$D < 10 ? `0${value.$D}` : value.$D;

		const month = value.$M < 9 ? `0${value.$M + 1}` : value.$M + 1;
		const formattedDate = `${value.$y}-${month}-${date}`;

		setState((prev) => ({
			...prev,
			unBorrowTime: formattedDate,
		}));
	};

	const fetchStudent = async () => {
		try {
			const res = await api.student.related(classId);

			const transformedOptions = res.map(({ id, firstName, lastName }) => ({
				value: id,
				label: `${firstName} ${lastName}`,
			}));
			setState((prev) => ({
				...prev,
				student: transformedOptions,
			}));
		} catch (err) {
			console.log("Error fetching student data:", err);
		}
	};

	useEffect(() => {
		if (classId) {
			fetchStudent();
		}
	}, [classId]);

	const handleSubmit = () => {
		if (!submitting) {
			setState((prevState) => ({ ...prevState, submitting: true }));
			onUpdate(null, true);
			navigate(-1);
			// form
			// 	.validateFields()
			// 	.then((values) => {
			// 		setState((prevState) => ({ ...prevState, submitting: true }));
			// 		if (values.file?.id) values.fileId = values.file.id ? values.file.id : "";
			// 		//   values.id = data.id;
			// 		delete values.file;
			// 		// values.id = data.id;

			// 		const action = hash === `#${Modals.EDIT}` ? api.pixel.update(values, id) : api.pixel.create(values);

			// 		const params = values;
			// 		action
			// 			.then(() => {
			// 				onUpdate(null, true);
			// 				navigate(-1);
			// 			})
			// 			.catch(() => {
			// 				setState((prevState) => ({ ...prevState, submitting: false }));
			// 			});
			// 	})
			// 	.catch(() => {});
		}
	};

	const disabledDateTime = () => ({
		disabledHours: () => range(0, 24).splice(4, 20),
		disabledMinutes: () => range(30, 60),
		disabledSeconds: () => [55, 56],
	});

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.ADDBORROW}`: {
				setState((prevState) => ({ ...prevState, visible: true }));
				// setState((prevState) => ({ ...prevState,  }));
				api.pixel
					.libraryById(data.id)
					.then((res) => {
						setState((prevState) => ({ ...prevState, visible: true, studentsList: res.students }));
						console.log("data.students ", res);
					})
					.catch((e) => console.log(e));
				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);
	// useEffect(() => {
	// 	if (data) {
	// 	}
	// }, [hash]);
	// const onClose = () => {
	// 	fetchData();
	// 	navigate(-1);
	// };

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={`${hash === `#${Modals.EDIT}` ? resources.edit : resources.add} `}
			maskClosable={false}
			open={visible}
			width={500}
		>
			{studentsList && studentsList.length > 0 ? (
				<div>
					<h3>{resources.students}</h3>
					<ul>
						{studentsList.map((student) => (
							<div
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									padding: "30px",
									borderBottom: "1px solid #e8e8e8",
								}}
							>
								<span style={{ flex: 1, fontSize: "16px", padding: "2px" }}>
									{student.firstName} {student.lastName}
								</span>
								<DeleteOutlined onClick={() => unborrowHandler(student.id)} />{" "}
							</div>
						))}
					</ul>
				</div>
			) : (
				<p style={{ padding: "30px" }}>
					{resources.to} {resources.none} {resources.student}
					{resources.y} {resources.borrow} {resources.dont}
				</p>
			)}

			<Form
				layout="vertical"
				form={form}
			>
				{hash === `#${Modals.ADDBORROW}` &&
					(data.borrowed ? (
						<>
							<Button
								onClick={unborrowHandler}
								loading={submitting}
							>
								{cancel ? "لغو شد" : `لغو امانت`}
							</Button>
						</>
					) : (
						<>
							<Col>
								<Form.Item name="class">
									<Select
										placeholder={resources.class}
										options={classes}
										onChange={handleStudentFilter}
										allowClear
									/>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item name="students">
									<Select
										placeholder={resources.students}
										options={student}
										filterOption={false}
										showSearch
										onChange={selectedStudentHandler}
										allowClear
									/>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item
									name="borrowTime"
									label={resources.borrowTime}
									initialValue={""}
								>
									<DatePickerJalali
										format="YYYY-MM-DD"
										style={{ display: "block" }}
										onChange={borrowTimeHandler}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item
									name="unBorrowTime"
									label={resources.unBorrowTime}
									initialValue={""}
								>
									<DatePickerJalali
										format="YYYY-MM-DD"
										style={{ display: "block" }}
										onChange={unBorrowTimeHandler}
									/>
								</Form.Item>
							</Col>

							<Button
								onClick={borrowHandler}
								loading={submitting}
								disabled={browed ? true : false}
							>
								{browed ? "انجام شد" : `امانت`}
							</Button>
						</>
					))}
			</Form>

			{/* {resArray && (
				<Card>
					{data.map((el) => {
						return <p>{el}</p>;
					})}
				</Card>
			)} */}
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
