import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Checkbox, Button, Form, Modal, Table, Tooltip, Card, Col, Row, Image } from "antd";

import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import moment, { months } from "moment-jalaali";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";
import FormModal from "modals/student/DisExcusedForm";

const Component = ({ permissions, setRoute }) => {
	const initialState = {
		data: [],
		pagination: {},
		date: moment().format(process.env.REACT_APP_DATE_FORMAT).split(" "),
	};
	const [checked, setChecked] = useState(true);

	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
      render: (value, record, index) => index + 1,
		},
		Table.EXPAND_COLUMN,
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: resources.title,
			dataIndex: "title",
			className: "center-cell",
		},
		{
			title: resources.assessment,
			dataIndex: "assessment",
			className: "center-cell ltr-cell",
		},
		{
			title: `${resources.Excused}/${resources.NotExcused}`,
			dataIndex: "isExcused",
			className: "center-cell ltr-cell",
			width: "100px",
			render: (value, record) => (
				<>
					{record.isExcused}
					<Checkbox
						title={resources.edit}
						checked={value}
					></Checkbox>
				</>
			),
		},
		// {
		// 	title: `${resources.description} ${resources.parent}`,
		// 	dataIndex: "parentDescription",
		// 	className: "center-cell",
		// 	// width: "120px",
		// 	render: (value, record) => (
		// 		<>
		// 			{record.needsParentDescription ? (
		// 				<Tooltip title={resources.edit}>
		// 					<Button
		// 						type="link"
		// 						icon={<EditOutlined />}
		// 						onClick={() => handleForm(record)}
		// 					/>
		// 				</Tooltip>
		// 			) : (
		// 				<></>
		// 			)}
		// 		</>
		// 	),
		// },
	];
	const [state, setState] = useState(initialState);
	const { classes, data, loading, pagination, selected, date, barState } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue(params);
		params.type = "Latency";
		setState((prevState) => ({ ...prevState, loading: true }));

		api.stPanel
			.DisReport({
				...params,
				pageSize: process.env.REACT_APP_PAGE_SIZE,
			})
			.then((data) => {
				let reportList = data.list;

				let barList = data.state;
				setState((prevState) => ({
					...prevState,
					data: reportList,
					barState: barList,
					loading: false,
					pagination,
				}));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (record, reload) => {
		// //console.log("line 92 is == ", value);
		//console.log("line 93 is == ", record);

		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected: record }));

			navigate(`${pathname}${search}#${Modals.EDIT}`);
		}
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleDate = (value) => {
		//console.log(" log line 121 is ", value);
		let startMonths = value[0].$M + 1;
		let endMonths = value[1].$M + 1;
		let startDate = `${value[0].$y}-${startMonths}-${value[0].$D}`;
		let endDate = `${value[1].$y}-${endMonths}-${value[1].$D}`;
		//console.log("log line 124 value ", endDate);

		api.stPanel
			.DisGeneralReport()
			.then((data) => {
				//console.log(data);
				let reportList = data.list;
				setState((prevState) => ({
					...prevState,
					data: reportList,
					loading: false,
				}));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	// const componentDidUpdate = () => {
	// 	fetchData();
	// };

	const componentDidMount = () => {
		setRoute();
		fetchData();
	};

	const expandedRowRender = (record) => {
		let img = `${ENV.basePath}${record.image}`;

		const columns = [
			{
				title: resources.description,
				dataIndex: "description",
				key: "description",
			},
			// {
			// 	title: `${resources.description} ${resources.parent}`,
			// 	dataIndex: "parentDescription",
			// 	key: "parentDescription",
			// },
			// {
			// 	title: ` ${resources.image} ${resources.description} ${resources.parent}`,
			// 	key: "parentImage",
			// 	render: (value, record) => (
			// 		<>
			// 			{record.parentImage != "-" ? (
			// 				<Image
			// 					src={`${ENV.basePath}${record.parentImage}`}
			// 					// shape="square"
			// 					width={40}
			// 					height={40}
			// 				/>
			// 			) : (
			// 				<>-</>
			// 			)}
			// 		</>
			// 	),
			// },
		];
		const data = [];
		data.push({
			description: record.description,
			parentDescription: record.parentDescription,
			parentImage: record.parentImage,
		});

		return (
			<Table
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
		);
	};
	// useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	return (
		<>
			{/* <Tabs
				className="sp-tabs"
				activeKey={5}
				items={[
					{ key: 1, label: <Link to={paths.edustudent.dailyReports}>{`${resources.dailyreport}`}</Link> },
					{ key: 2, label: <Link to={paths.edustudent.classAssessments}>{`${resources.assessment} ${resources.class}${resources.y}`}</Link> },
					{ key: 3, label: <Link to={paths.edustudent.monthlyGradeSheet}>{`${resources.workbook} ${resources.monthly}`}</Link> },
					{ key: 4, label: <Link to={paths.edustudent.generalExams}>{`${resources.exam} ${resources.general}`}</Link> },
					{ key: 5, label: <Link to={paths.edustudent.generalReports}>{`${resources.report} ${resources.total}`}</Link> },
				]}
			/> */}

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
				expandable={{
					expandedRowRender: (record) => expandedRowRender(record),
				}}
			/>
			{/* <FormModal
				data={selected}
				onUpdate={handleForm}
			/> */}
			{contextHolder}
		</>
	);
};

export default Component;
