import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Card, Col, Form, Row, Select, Table, DatePicker, Image, Input, Button, Checkbox } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { SearchOutlined } from "@ant-design/icons";

import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

const { RangePicker } = DatePicker;

const Component = ({ setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");
	const initialState = {
		classes: [],
		data: [],
		pagination: {},
		students: [],
		EducationalYear: [],
	};
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
		},
		Table.EXPAND_COLUMN,
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: resources.student,
			dataIndex: "name",
		},
		{
			title: resources.regulation,
			dataIndex: "regulation",
			className: "center-cell",
		},
		// {
		// 	title: resources.description,
		// 	dataIndex: "descrption",
		// 	className: "center-cell",
		// 	render: (value) => (value ? value?.descrption : "-"),
		// },
		{
			title: resources.assessment,
			dataIndex: "assessment",
			className: "center-cell",
		},
		{
			title: `${resources.seen} ${resources.parent} `,
			dataIndex: "needsParentDescription",
			className: "center-cell",
			editable: true,
			render: (value, record) => (
				<>
					{record.needsParentDescription}
					<Checkbox
						title={`${resources.seen} ${resources.parent} `}
						checked={value}
						// onClick={() => handleCheckbox(value, record, "needsParentDescription")}
					></Checkbox>
				</>
			),
		},
		{
			title: `${resources.hour} ${resources.call}`,
			dataIndex: "callTime",
			className: "center-cell",
			render: (value, record) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return record.callTime ? (
					<>
						<span className="name">{time}</span>
					</>
				) : (
					<>-</>
				);
			},
		},
		// {
		//   title: `${resources.Excused}`,
		//   dataIndex: "isExcused",
		//   className: "center-cell",
		//   editable: true,
		//   render: (value, record) => (
		//     <>
		//       {record.isExcused}
		//       <Checkbox
		//         title={resources.edit}
		//         checked={value}
		//         onClick={() => handleCheckbox(value, record)}
		//       ></Checkbox>
		//     </>
		//   ),
		// },
	];
	const [state, setState] = useState(initialState);
	const { classes, data, barState, fileState, loading, pagination, students, selectedStudent, EducationalYear } = state;
	const { pathname, search } = useLocation();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue({
			...params,
			start: params.start ? dayjs(params.start) : undefined,
			end: params.end ? dayjs(params.end) : undefined,
		});
		// if (params.classId) {
			setState((prevState) => ({ ...prevState, loading: true }));

			api.classesItems
				.educational({
					...params,
					pageSize: process.env.REACT_APP_PAGE_SIZE,
				})
				.then(({ list: data, pagination }) => {
					setState((prevState) => ({
						...prevState,
						data,
						pagination,
						loading: false,
					}));
				})
				.catch(() => {
					setState((prevState) => ({ ...prevState, loading: false }));
				});
		// } else {
		// 	setState((prevState) => ({ ...prevState, data: [] }));
		// }
	};

	const handleStudents = (selectedStudent) => {
		setState((prevState) => ({ ...prevState, selectedStudent }));
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const params = GetUrlParams(search);

			if (values.start) {
				let months = values.start.$M + 1;
				let start = `${values.start.$y}-${months}-${values.start.$D}`;
				values.start = start;
			}
			if (values.end) {
				let months = values.end.$M + 1;
				let end = `${values.end.$y}-${months}-${values.end.$D}`;
				values.end = end;
			}

			const _params = params.class === values.class ? params : {};

			const _search = ObjectToURL({ page: 1, ..._params, ...values });

			navigate(`${pathname}?${_search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.class
			.related()
			.then((classes) => {
				setState((prevState) => ({ ...prevState, classes }));
			})
			.catch(() => {});

		api.educationalYear
			.list()
			.then((EducationalYear) => {
				setState((prevState) => ({ ...prevState, EducationalYear }));
			})
			.catch(() => {});
	};

	const expandedRowRender = (record) => {
		console.log("expandedRowRender expandedRowRender : ", expandedRowRender);

		const columns = [
			{
				title: resources.description,
				dataIndex: "descrption",
				key: "descrption",
			},
			// {
			// 	title: `${resources.description} ${resources.parent}`,
			// 	dataIndex: "parentDescription",
			// 	key: "parentDescription",
			// },
			// {
			// 	title: ` ${resources.image} ${resources.description} ${resources.parent}`,
			// 	key: "parentImage",
			// 	render: (value) => (
			// 		<>
			// 			{value.parentImage ? (
			// 				<Image
			// 					src={`${ENV.basePath}${value.parentImage}`}
			// 					// shape="square"
			// 					width={40}
			// 					height={40}
			// 				/>
			// 			) : (
			// 				<>-</>
			// 			)}
			// 		</>
			// 	),
			// },
		];
		const data = [];
		data.push({
			descrption: record.descrption,
			// parentDescription: record.parentDescription,
			// parentImage: record.parentImage,
		});

		return (
			<Table
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
		);
	};
	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	return (
		<>
			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="classId">
							<Select
								placeholder={resources.class}
								options={classes}
								onChange={handleFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					{/*  */}
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="EducationalYear">
							<Select
								placeholder={`${resources.academicYear}`}
								options={EducationalYear}
								onChange={handleFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					{/*  */}
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="start">
							<DatePickerJalali
								placeholder={`${resources.date} ${resources.start}`}
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="end">
							<DatePickerJalali
								placeholder={`${resources.date} ${resources.end}`}
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
				expandable={{
					expandedRowRender: (record) => expandedRowRender(record),
				}}
			/>
			{/* expandable={{ expandedRowRender: (record) => `${resources.description}: ${record.description}` }} */}
		</>
	);
};

export default Component;
