import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Checkbox, TimePicker, Drawer, Form, Input, message } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

const Component = ({ data = {}, onUpdate, options: { classes = [] } }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible, teachers } = state;
	const { id } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const format = "HH:mm";

	// const handleSubmit = () => {
	//   if (!submitting) {
	//     form
	//       .validateFields()
	//       .then((values) => {
	//         values.date.$D =
	//           values.date.$D < 10 ? `0${values.date.$D}` : values.date.$D;
	//         values.date.$M =
	//           values.date.$M < 9 ? `0${values.date.$M + 1}` : values.date.$M + 1;
	//         const formattedDate = `${values.date.$y}-${values.date.$M}-${values.date.$D}`;
	//         values.date = formattedDate;

	//         const startMonth =
	//           values.start.$M < 9
	//             ? `0${values.start.$M + 1}`
	//             : values.start.$M + 1;
	//         const endMonth =
	//           values.end.$M < 9 ? `0${values.end.$M + 1}` : values.end.$M + 1;

	//         let startDay =
	//           values.start.$D < 9 ? `0${values.start.$D}` : values.start.$D;
	//         let endDay = values.end.$D < 9 ? `0${values.end.$D}` : values.end.$D;

	//         let startHour =
	//           values.start.$H < 9 ? `0${values.start.$H}` : values.start.$H;
	//         let endHour =
	//           values.end.$H < 9 ? `0${values.end.$H}` : values.end.$H;

	//         let startMinut =
	//           values.start.$m < 9 ? `0${values.start.$m}` : values.start.$m;
	//         let endMinut =
	//           values.end.$m < 9 ? `0${values.end.$m}` : values.end.$m;

	//         values.start = `${values.start.$y}-${startMonth}-${startDay}T${startHour}:${startMinut}:07.399Z`;
	//         values.end = `${values.end.$y}-${endMonth}-${endDay}T${endHour}:${endMinut}:07.399Z`;

	//         // startHour ='';
	//         // delete values.start.$M;
	//         setState((prevState) => ({ ...prevState, submitting: true }));

	//         const action =
	//           hash === `#${Modals.EDIT}`
	//             ? api.meetingManagment.reserve(values, id)
	//             : api.meetingManagment.create(values);

	//         action
	//           .then(() => {
	//             onUpdate(null, true);
	//             navigate(-1);
	//           })
	//           .catch(() => {
	//             setState((prevState) => ({ ...prevState, submitting: false }));
	//           });
	//       })
	//       .catch(() => {});
	//   }
	// };

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					const startHour = values.start.$H < 10 ? `0${values.start.$H}` : values.start.$H;
					const endHour = values.end.$H < 10 ? `0${values.end.$H}` : values.end.$H;

					const startMinute = values.start.$m < 10 ? `0${values.start.$m}` : values.start.$m;
					const endMinute = values.end.$m < 10 ? `0${values.end.$m}` : values.end.$m;

					const startSecond = values.start.$s < 10 ? `0${values.start.$s}` : values.start.$s;
					const endSecond = values.end.$s < 10 ? `0${values.end.$s}` : values.end.$s;

					values.start = `${startHour}:${startMinute}:${startSecond}`;
					values.end = `${endHour}:${endMinute}:${endSecond}`;

					setState((prevState) => ({ ...prevState, submitting: true }));

					const action = hash === `#${Modals.EDIT}` ? api.meetingManagment.reserve(values, id) : api.meetingManagment.create(values);

					action
						.then(() => {
							message.success(`${resources.message.succses}`);
							onUpdate(null, true);
							navigate(-1);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const classHandler = (selectedClass) => {
		let classId = selectedClass;
		if (classId) {
			api.meetingManagment
				.teacherList(classId)
				.then((data) => {
					setState((prevState) => ({
						...prevState,
						teachers: data.map(({ id, firstName, lastName }) => ({
							value: id,
							label: `${firstName} ${lastName}`,
						})),
					}));
				})
				.catch(() => {});
		}
	};
	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true }));

					form.setFieldsValue({
						...data,
						date: dayjs(data.date),
						start: dayjs(data.start),
						end: dayjs(data.end),
						assessmentId: data.assessment?.id,
						lesson: data.lesson?.value,
						regulationId: data.regulation?.id,
						studentId: data.student?.id,
						effectOnBar: !data.effectOnBar,
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={`${hash === `#${Modals.EDIT}` ? resources.edit : resources.add} ${`${resources.meet} ${resources.with} ${resources.management}`}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				{/* <Form.Item name="class" label={resources.class} rules={rules.required}>
          <Select options={classes} onChange={classHandler} />
        </Form.Item> */}
				{/* <Form.Item
          name="teacher"
          label={resources.teacher}
          rules={rules.required}
        >
          <Select options={teachers} />
        </Form.Item> */}
				<Form.Item
					name="date"
					label={resources.date}
					rules={rules.required}
				>
					<DatePickerJalali style={{ display: "block" }} />
				</Form.Item>
				<Form.Item
					name="start"
					label={`${resources.hour} ${resources.start}`}
					rules={rules.required}
				>
					<TimePicker format={format} />
				</Form.Item>
				<Form.Item
					name="end"
					label={`${resources.hour} ${resources.end}`}
					rules={rules.required}
				>
					<TimePicker format={format} />
				</Form.Item>
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
