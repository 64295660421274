import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StudentCarousel from "components/StudentCarousel";

import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { Col, FloatButton, Modal, Row, Select, Table, Image, Tooltip, message, Alert, Button, Drawer, Form, Input } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import locale from "antd/locale/fa_IR";
// import "dayjs/locale/fa_IR";
// import UploadDocuments from "components/UploadDocuments";
import { BookOutlined, CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, CloseOutlined, AppstoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

import UploadImage from "components/UploadImage";
const Component = ({
	data = {},
	onUpdate,
	options: { classes = [] },
	fetchData,
	//   students
}) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {
		selected: [],
		student: [],
		cancel: false,
		browed: false,
		borrowTime: "",
		unBorrowTime: "",
	};
	const [state, setState] = useState(initialState);
	const { submitting, visible, cancel } = state;
	const { id } = data;
	const { classId, student, selected, browed, borrowTime, unBorrowTime } = state;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	var d = new Date(2018, 8, 18);

	const handleStudentFilter = (value) => {
		setState((prev) => ({
			...prev,
			classId: value,
		}));
	};

	const selectedStudentHandler = (value) => {
		setState((prev) => ({
			...prev,
			selected: value,
		}));
	};


	const fetchStudent = async () => {
		try {
			const res = await api.student.related(classId);

			const transformedOptions = res.map(({ id, firstName, lastName }) => ({
				value: id,
				label: `${firstName} ${lastName}`,
			}));
			setState((prev) => ({
				...prev,
				student: transformedOptions,
			}));
		} catch (err) {
			console.log("Error fetching student data:", err);
		}
	};

	useEffect(() => {
		if (classId) {
			fetchStudent();
		}
	}, [classId]);
	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));
					if (values.file?.id) values.fileId = values.file.id ? values.file.id : "";
					//   values.id = data.id;
					delete values.file;
					// values.id = data.id;
					console.log("VALUEEEE", values);

					const action = hash === `#${Modals.EDIT}` ? api.pixel.update(values, id) : api.pixel.create(values);

					const params = values;
					action
						.then(() => {
							onUpdate(null, true);
							navigate(-1);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const disabledDateTime = () => ({
		disabledHours: () => range(0, 24).splice(4, 20),
		disabledMinutes: () => range(30, 60),
		disabledSeconds: () => [55, 56],
	});

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				setState((prevState) => ({ ...prevState, visible: true }));
				console.log("data data data ", data);

				form.setFieldsValue({
					...data,
					title: data.title,
					borrowTime: dayjs(data.borrowTime),
					unBorrowTime: dayjs(data.unBorrowTime),
					description: data.description,
					file: data.fileUrl ? data.fileUrl : "",
				});

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={`${hash === `#${Modals.EDIT}` ? resources.edit : resources.add} `}
			maskClosable={false}
			open={visible}
			width={500}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="title"
					label={`${resources.subject}`}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="description"
					label={`${resources.description} `}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="file"
					label={resources.file}
					initialValue={""}
				>
					<UploadImage />
				</Form.Item>
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
