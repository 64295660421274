import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, DatePicker, Drawer, Form, Input, Select } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

const Component = ({ data = {}, onUpdate, options: { assessments = [], lessons = [], selectStudent = [] } }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible } = state;
	const { id, topic } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					const selectedDay = values.date.$D < 10 ? `0${values.date.$D}` : values.date.$D;
					const selectedMonth = values.date.$M < 9 ? `0${values.date.$M + 1}` : values.date.$M + 1;
					const formattedDate = `${values.date.$y}-${selectedMonth}-${selectedDay}`;
					values.date = formattedDate;

					if (hash === `#${Modals.CREATE}`) {
						values.effectOnBar = !values.effectOnBar;
						for (let value of values.studentId) {
							values.studentId = value;
							setState((prevState) => ({ ...prevState, submitting: true }));

							const action = api.classAssessment.create(values);
							action
								.then((res) => {})
								.catch(() => {
									setState((prevState) => ({ ...prevState, submitting: false }));
								});
						}
						setTimeout(function () {
							onUpdate(null, true);
							navigate(-1);
						}, 500);
					} else {
						values.effectOnBar = !values.effectOnBar;
						setState((prevState) => ({ ...prevState, submitting: true }));

						const action = api.classAssessment.update(values, id);
						action
							.then((res) => {
								setTimeout(function () {
									onUpdate(null, true);
									navigate(-1);
								}, 500);
							})
							.catch(() => {
								setState((prevState) => ({ ...prevState, submitting: false }));
							});
					}
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true }));

					form.setFieldsValue({
						...data,
						date: dayjs(data.date),
						assessmentId: data.assessment?.id,
						lesson: data.lesson?.value,
						studentId: data.student?.id,
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={
				hash === `#${Modals.EDIT}`
					? `${resources.edit} ${resources.assessment} ${resources.class} "${topic}"`
					: `${resources.add} ${resources.assessment} ${resources.class}`
			}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="topic"
					label={`${resources.subject} ${resources.lesson}`}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="lesson"
					label={resources.lesson}
					rules={rules.required}
				>
					<Select options={lessons} />
				</Form.Item>
				<Form.Item
					name="assessmentId"
					label={resources.assessment}
					allowClear
				>
					<Select
						options={assessments}
						allowClear
					/>
				</Form.Item>
				<Form.Item
					name="studentId"
					label={resources.student}
					rules={rules.required}
				>
					<Select
						mode="multiple"
						options={selectStudent}
						filterOption={false}
					/>
				</Form.Item>
				<Form.Item
					name="date"
					label={resources.date}
					rules={rules.required}
				>
					<DatePickerJalali style={{ display: "block" }} />
				</Form.Item>
				<Form.Item
					name="description"
					label={resources.description}
				>
					<Input.TextArea rows={4} />
				</Form.Item>
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
