export default {
	absence: "غیبت",
	account: "حساب کاربری",
	action: "عملیات",
	academicYear: "سال تحصیلی",
	add: "افزودن",
	address: "آدرس",
	toall: "به همه",
	and: "و",
	assessment: "ارزیابی",
	bank: "بانک تام",
	birthday: "تاریخ تولد",
	book: "کتاب",
	borrow: "امانت",
	borrowTime: "تاریخ شروع امانت",
	bus: "سرویس",
	cancel: "لغو",
	calendar: "گاه شمار",
	call: "تماس",
	camping: "اردو",
	ceremony: "مراسم",
	class: "پایه",
	close: "بستن",
	create: "ساخت",
	color: "رنگ",
	config: "پیکربندی",
	confirm: "تایید",
	copyright: "تمامی حقوق این نرم افزار متعلق به مدرسه مهارت می‌باشد.",
	creditor: "بستانکار",
	daily: "روزانه",
	dailyreport: "موارد آموزشی",
	dashboard: "خانه",
	date: "تاریخ",
	debtor: "بدهکار",
	deadline: "مهلت",
	delete: "حذف",
	deleteConfirm: "از حذف این آیتم اطمینان دارید؟",
	deleteEventConfirm: "از حذف این رویداد اطمینان دارید؟",
	description: "توضیحات",
	discipline: "شایستگی‌ها",
	documents: "مدارک",
	done: "شده",
	dont: "نشده",
	edit: "ویرایش",
	eduDailyActivity: "کارکرد روزانه کلاسی",
	event: "رویداد",
	education: "آموزش",
	enter: "ورود",
	end: "پایان",
	exam: "آزمون",
	emergency: "اورژانسی",
	Excused: "موجه",
	NotExcused: "ناموجه",
	exit: "خروج",
	father: "پدر",
	file: "فایل",
	financial: "مالی",
	first: "اول",
	friday: "جمعه",
	firstName: "نام",
	general: "فراگیر",
	generalTeacher: "آموزگار فراگیر",
	giver: "دهنده",
	games: "مسابقات",
	grade: "پایه",
	ha: "‌ها",
	healthCard: "برگ سلامت",
	hour: "ساعت",
	hurry: "تعجیل",
	image: "تصویر",
	items: "موارد",
	lastName: "نام خانوادگی",
	latency: "دیرکرد",
	lesson: "درس",
	library: "کتابخانه",
	make: "ایجاد",
	management: "مدیریت",
	manciple: "ناظم",
	medicalExaminationsCard: "کارت خلاصه معاینات",
	meet: "فرصت گفت‌وگو",
	mobile: "موبایل",
	monthly: "ماهانه",
	mother: "مادر",
	monday: "دوشنبه",
	name: "نام",
	nationalCode: "کد ملی",
	nationalCard: "کارت ملی",
	need: "نیاز به",
	notfound: "متأسفیم، چنین صفحه‌ای وجود ندارد.",
	no: "خیر",
	none: "هیچ",
	open: "باز کردن",
	other: "سایر",
	page: "صفحه",
	password: "گذرواژه",
	parent: "خانواده",
	parents: "والدین",
	payment: "پرداخت",
	pixel: "پیکسل",
	pollution: "آلودگی",
	phone: "تلفن",
	place: "مکان",
	profile: "رخ‌نما",
	read: "خواندن",
	record: "ثبت",
	remaining: "مانده",
	required: "الزامی",
	regulation: "آیین‌نامه",
	refer: "ارجاع",
	refers: "واگذاری",
	referConfirm: "از ارجاع این آیتم اطمینان دارید؟",
	reserveManagementMeeting: "رزرو دیدار با مدیر",
	removeReserveManagementMeeting: "لغو رزرو دیدار با مدیر",
	renew: "تمدید",
	renewText: "از تمدید این آیتم اطمینان دارید؟",
	report: "گزارش",
	role: "نقش",
	row: "ردیف",
	reserve: "رزرو",
	return: "برگشت",
	score: "امتیاز",
	school: "مدرسه",
	search: "جستجو",
	seen: "دیدن",
	send: "ارسال",
	shenasname: "شناسنامه",
	special: "ویژه",
	status: "جایگاه",
	start: "شروع",
	saturday: "شنبه",
	student: "دانش‌آموز",
	students: "دانش‌آموزان",
	subject: "موضوع",
	sunday: "یکشنبه",
	suspend: "محدودیت ها",
	take: "دریافت",
	teacher: "آموزگار",
	thursday: "پنجشنبه",
	tuesday: "سه‌شنبه",
	text: "متن",
	test: "سنجش",
	time: "زمان",
	type: "نوع",
	title: "سرنویس",
	to: "به",
	total: "فراگیر",
	totalAbsences: "مجموع غیبت",
	totalDelay: "مجموع دیرکرد",
	totalAccelerations: "مجموع تعجيل",
	toy: "بازی فکری",
	tuition: "شهریه",
	unBorrowTime: "تاریخ پایان امانت",
	update: "بروزرسانی",
	upload: "بارگذاری",
	user: "کاربر",
	userInfo: "اطلاعات کاربر",
	users: "کاربران",
	username: "نام کاربری",
	vaccineCard: "کارت واکسن",
	vacation: "تعطیل",
	view: "نمایش",
	Virtual: "مجازی",
	warning: "اخطار",
	wednesday: "چهارشنبه",
	with: "با",
	wihtout: "بدون",
	workbook: "کارنامه",
	duration: "مدت (دقیقه)",
	state: "وضعیت",
	y: "ی",
	yes: "بله",
	StudentPhotos: "تصویر دانش آموز",
	StudentBirthCertificate: "پویش(اسکن) شناسنامه دانش آموز",
	FatherBirthCertificate: "پویش(اسکن) شناسنامه پدر",
	MotherBirthCertificate: "پویش(اسکن) شناسنامه مادر",
	FatherNationalCard: "پویش(اسکن) کارت ملی پدر",
	MotherNationalCard: " پویش(اسکن) کارت ملی مادر",
	StudentVaccineCard: "کارت واکسن دانش آموز",
	StudentsTestResult: "سنجش دانش آموز",
	StudentExaminations: "کارت خلاصه معاینات",
	HealthCard: "برگ سلامت",
	Other: "سایر",

	Required: "ارسال نشده",
	Rejected: "رد شده",
	Completed: "تایید شده",
	Awaiting: "در انتظار تایید",
	accept: "تایید کردن",
	reject: "رد کردن",

	marginbottom: "30px",

	message: {
		succses: "عملیات با موفقیت انجام شد.",
		error: "لطفا دوباره تلاش کنید؛ عملیات انجام نشد.",
	},

	uploadErros: {
		size: "حجم فایل باید کمتر از 10 مگ باشد",
		extension: "فرمت فایل معتبر نمی‌باشد",
	},

	// months
	farvardin: "فروردین",
	ordibehesht: "اردیبهشت",
	khordad: "خرداد",
	tir: "تیر",
	mordad: "مرداد",
	sharivar: "شهریور",
	mehr: "مهر",
	aban: "آبان",
	azar: "آذر",
	dey: "دی",
	bahman: "بهمن",
	esfand: "اسنفد",
};
