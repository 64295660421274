import { message } from "antd";
import axios from "axios";
import { DelCookie, GetCookie, SetCookie } from "scripts/helpers";
import { ENV } from "scripts/settings";
import paths from "scripts/paths";

const api = axios.create({
	baseURL: `${ENV.basePath}${process.env.REACT_APP_API_URL}`,
	headers: { accept: "application/json" },
});

const delCookie = () => DelCookie(process.env.REACT_APP_TOKEN_NAME, location.hostname);
const getCookie = () => GetCookie(process.env.REACT_APP_TOKEN_NAME);
const setCookie = (token, days) => SetCookie(process.env.REACT_APP_TOKEN_NAME, location.hostname, token, days);

api.interceptors.request.use(
	(config) => {
		const token = getCookie();

		if (token) {
			config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
		}

		return config;
	},
	(error) => {
		return Promise.reject(error.response);
	}
);

api.interceptors.response.use(
	(response) => {
		if (response.data.messages) {
			if (response.data.messages.length > 0) message.error(response.data.messages);
		}

		return response?.data;
	},
	({ response }) => {
		if (response) {
			// if (response.data.messages) message.error(response.data.messages);

			if (response.status >= 400 && response.status < 500) {
				if (response.status == 401) {
					message.error(".نام کاربری یا گذرواژه اشتباه است");
				} else if (response.status == 403) {
					message.error("شما اجازه دسترسی به این صفحه را ندارید.");
				} else if (response.status == 400) {
					if (response.data.messages) {
						message.error(response.data.messages);
					} else {
						message.error("لطفا مقادیر ورودی را بررسی کنید.");
					}
				}

				console.error("Client Error:", response.status);
				// Handle the error, show a message to the user, or perform other actions
			} else if (response.status >= 500) {
				// Do something when the status code is 500 or higher
				// console.error("Server Error:", response.data.Message);
				message.error(response.data.messages);
				// Handle the error, show a message to the user, or perform other actions
			} else {
				// Do something when the status code is in the 200 range (e.g., 200 OK)
				//console.log("Success:", response.status);
				message.error(response.status);
				// Parse the response JSON or do other processing
				// return response.json(); // This returns a promise with the JSON data
			}
			switch (response.status) {
				case 401:
					//window.location.href = paths.auth.login;
					break;
				default:
					break;
			}
		}

		return Promise.reject(response?.data);
	}
);

const get = async (url, params = {}) => {
	return await api.get(url, { params });
};

const post = async (url, params = {}) => {
	//console.log("api post log 59", params);
	return await api.post(url, params);
};

const put = async (url, params = {}) => {
	return await api.put(url, params);
};

const del = async (url, params = {}) => {
	return await api.delete(url, params);
};

const formData = async (url, params = {}, progress) => {
	return await api.post(url, params, {
		headers: { "Content-Type": "multipart/form-data" },
		onUploadProgress: (e) => {
			if (progress) progress(Math.floor((e.loaded / e.total) * 100));
		},
	});
};

export default {
	disGeneralReports: (params) => {
		return get("DisciplinaryGeneralReport", params);
	},
	eduGeneralReports: (params) => {
		return get("EducationalGeneralReport", params);
	},
	file: (params, progress) => {
		return formData("File", params, progress);
	},
	grades: () => {
		return get("Grade");
	},
	lessons: () => {
		return get("Lesson");
	},
	notifications: () => {
		return get(`Notification`);
	},
	permissions: () => {
		return get("Permission");
	},
	assessment: {
		create: (params) => {
			return post("Assessment", params);
		},
		delete: (id) => {
			return del(`Assessment?id=${id}`);
		},
		list: (params) => {
			return get("Assessment/All", params);
		},
		select: (id) => {
			return get(`Assessment?id=${id}`);
		},
		update: (params, id) => {
			return put(`Assessment?id=${id}`, params);
		},
	},
	auth: {
		changePass: (params) => {
			return post("Account/change-password", params);
		},
		login: (params) => {
			return post("Account/login", params);
		},
		logout: () => {
			return get("Account/logout");
		},
		me: () => {
			return get("Account/me");
		},
	},
	bar: {
		educational: () => {
			return post(`Bar/Educational`);
		},
		disciplinary: () => {
			return post(`Bar/Disciplinary`);
		},
	},
	class: {
		create: (params) => {
			return post("Class", params);
		},
		delete: (id) => {
			return del(`Class?id=${id}`);
		},
		list: (params) => {
			return get("Class/All", params);
		},
		select: (id) => {
			return get(`Class?id=${id}`);
		},
		update: (params, id) => {
			return put(`Class?id=${id}`, params);
		},
		related: () => {
			return get("Class/Related");
		},
	},
	classAssessment: {
		create: (params) => {
			return post("ClassAssessment", params);
		},
		delete: (id) => {
			return del(`ClassAssessment?id=${id}`);
		},
		list: (params) => {
			return get("ClassAssessment/All", params);
		},
		select: (id) => {
			return get(`ClassAssessment?id=${id}`);
		},
		update: (params, id) => {
			return put(`ClassAssessment?id=${id}`, params);
		},
		ByStudent: (params) => {
			return get(`ClassAssessment/ByStudent`, params);
		},
		ChangeShowToParents: (id) => {
			return put(`ClassAssessment/ChangeShowToParents?id=${id}`);
		},
	},
	classesItems: {
		disciplinary: (params) => {
			return get(`ClassesItems/Disciplinary`, params);
		},
		absence: (params) => {
			return get(`ClassesItems/Absence`, params);
		},
		educational: (params) => {
			return get(`ClassesItems/Educational`, params);
		},
		library: (params) => {
			return get(`ClassesItems/Library`, params);
		},
	},
	course: {
		create: (params) => {
			return post("Course", params);
		},
		list: (params) => {
			return get(`Course/ByClass?classId=${params.class}`, params);
		},
		delete: (id) => {
			return del(`Course?id=${id}`);
		},
	},
	dashboard: {
		create: (params) => {
			return post("Assessment", params);
		},
		delete: (id) => {
			return del(`Assessment?id=${id}`);
		},
		list: () => {
			return get("Dashboard");
		},
		select: (id) => {
			return get(`Assessment?id=${id}`);
		},
		update: (params, id) => {
			return put(`Assessment?id=${id}`, params);
		},
	},
	disRefer: {
		list: (params) => {
			return get("DisciplinaryReference/All", params);
		},
		delete: (id) => {
			return del(`DisciplinaryReference?id=${id}`);
		},
	},
	disRegulation: {
		create: (params) => {
			return post("DisciplinaryRegulation", params);
		},
		delete: (id) => {
			return del(`DisciplinaryRegulation?id=${id}`);
		},
		list: (params) => {
			return get("DisciplinaryRegulation/All", params);
		},
		select: (id) => {
			return get(`DisciplinaryRegulation?id=${id}`);
		},
		update: (params, id) => {
			return put(`DisciplinaryRegulation?id=${id}`, params);
		},
	},
	disReport: {
		create: (params) => {
			return post("DisciplinaryReport", params);
		},
		addToClass: (params) => {
			return post("DisciplinaryReport/AddToClass", params);
		},
		delete: (id) => {
			return del(`DisciplinaryReport?id=${id}`);
		},
		list: (params) => {
			return get("DisciplinaryReport/All", params);
		},
		select: (id) => {
			return get(`DisciplinaryReport?id=${id}`);
		},
		update: (params, id) => {
			return put(`DisciplinaryReport?id=${id}`, params);
		},
		limited: (params) => {
			return get("DisciplinaryReport/ByStudent", params);
		},
		refer: (id) => {
			return get(`DisciplinaryReport/Refer?id=${id}`);
		},
		makeExcuse: (id) => {
			return post(`DisciplinaryReport/MakeExcuse?id=${id}`);
		},
		closeProfile: (selectedStudentId) => {
			return post(`DisciplinaryReport/Suspend?id=${selectedStudentId}`);
		},
		openProfile: (selectedStudentId) => {
			return post(`DisciplinaryReport/RemoveSuspension?id=${selectedStudentId}`);
		},
		warning: (selectedStudentId) => {
			return post(`DisciplinaryReport/Warning?id=${selectedStudentId}`);
		},
		removewarning: (selectedStudentId) => {
			return post(`DisciplinaryReport/RemoveWarning?id=${selectedStudentId}`);
		},
		ChangeShowToParents: (id) => {
			return put(`DisciplinaryReport/ChangeShowToParents?id=${id}`);
		},
	},
	documents: {
		list: (params) => {
			return get(`Document/ByStudent?studentId=${params.student}`);
		},
		create: (params) => {
			return post("Document", params);
		},
		delete: (id) => {
			return del(`Document?id=${id}`);
		},
		updateState: (params, id) => {
			return put(`Document/ChangeState?id=${id}`, params);
		},
		update: (params, id) => {
			return put(`Document?id=${id}`, params);
		},
		closeProfile: (selectedStudentId) => {
			return post(`Document/Suspend?id=${selectedStudentId}`);
		},
		openProfile: (selectedStudentId) => {
			return post(`Document/RemoveSuspension?id=${selectedStudentId}`);
		},
		warning: (selectedStudentId) => {
			return post(`Document/Warning?id=${selectedStudentId}`);
		},
		removewarning: (selectedStudentId) => {
			return post(`Document/RemoveWarning?id=${selectedStudentId}`);
		},
	},
	eduRefer: {
		list: (params) => {
			return get("EducationalReference/All", params);
		},
		delete: (id) => {
			return del(`EducationalReference?id=${id}`);
		},
	},
	eduRegulation: {
		create: (params) => {
			return post("EducationalRegulation", params);
		},
		delete: (id) => {
			return del(`EducationalRegulation?id=${id}`);
		},
		list: (params) => {
			return get("EducationalRegulation/All", params);
		},
		select: (id) => {
			return get(`EducationalRegulation?id=${id}`);
		},
		update: (params, id) => {
			return put(`EducationalRegulation?id=${id}`, params);
		},
	},
	eduReport: {
		create: (params) => {
			return post("EducationalDailyReport", params);
		},
		addToClass: (params) => {
			return post("EducationalDailyReport/AddToClass", params);
		},
		delete: (id) => {
			return del(`EducationalDailyReport?id=${id}`);
		},
		list: (params) => {
			return get("EducationalDailyReport/All", params);
		},
		select: (id) => {
			return get(`EducationalDailyReport?id=${id}`);
		},
		update: (params, id) => {
			return put(`EducationalDailyReport?id=${id}`, params);
		},
		limited: (params) => {
			return get("EducationalDailyReport/ByStudent", params);
		},
		refer: (id) => {
			return get(`EducationalDailyReport/Refer?id=${id}`);
		},
		ChangeShowToParents: (id) => {
			return put(`EducationalDailyReport/ChangeShowToParents?id=${id}`);
		},
	},
	educationalYear: {
		list: () => {
			return get(`EducationalYear`);
		},
	},

	generalExam: {
		create: (params) => {
			return post("GeneralExam", params);
		},
		delete: (id) => {
			return del(`GeneralExam?id=${id}`);
		},
		list: (params) => {
			return get("GeneralExam/All", params);
		},
	},
	meeting: {
		list: (params) => {
			return get("Meeting/All", params);
		},
		teacherList: (classId) => {
			return get(`Meeting/TeachersList?classId=${classId}`);
		},
		create: (params) => {
			return post(`Meeting`, params);
		},
		delete: (id) => {
			return del(`Meeting?id=${id}`);
		},
		update: (params, id) => {
			return put(`Meeting/Reserve?id=${id}`, params);
		},
	},
	meetingManagment: {
		list: (params) => {
			return get("ManagementMeeting/All", params);
		},
		teacherList: (classId) => {
			return get(`ManagementMeeting/TeachersList?classId=${classId}`);
		},
		create: (params) => {
			return post(`ManagementMeeting`, params);
		},
		delete: (id) => {
			return del(`ManagementMeeting?id=${id}`);
		},
		reserve: (params, id) => {
			return put(`ManagementMeeting/Reserve?meeting=${id}`, params);
		},
		removeReserve: (params, id) => {
			return put(`ManagementMeeting/RemoveReserve?meeting=${id}`, params);
		},
		accept: (id) => {
			return put(`ManagementMeeting/Accept?meeting=${id}`);
		},
		reject: (id) => {
			return put(`ManagementMeeting/Reject?meeting=${id}`);
		},
	},
	//
	library: {
		list: (params) => {
			return get("Library/All", params);
		},
		libraryById: (id) => {
			return get(`Library/TeachersList?id=${id}`);
		},
		create: (params) => {
			return post(`Library`, params);
		},
		delete: (id) => {
			return del(`Library?id=${id}`);
		},
		update: (params, id) => {
			// return console.log("ID", params)
			return put(`Library?id=${id}`, params);
		},
		renew: (id) => {
			return put(`Library/Renew?id=${id}`);
		},
		forStudent: (params) => {
			return get("Library/ForStudent", params);
		},
	},

	pixel: {
		list: (params) => {
			return get("Pixel/All", params);
		},
		libraryById: (id) => {
			return get(`Pixel?id=${id}`);
		},
		create: (params) => {
			return post("Pixel", params);
		},
		delete: (id) => {
			return del(`Pixel?id=${id}`);
		},
		update: (params, id) => {
			return put(`Pixel?id=${id}`, params);
		},
		forStudent: (params) => {
			return get(`Pixel/ForStudent`, params);
		},

		borrow: (pixel, params) => {
			console.log("pixel", pixel, "pa", params);

			return put(`Pixel/Borrow?pixel=${pixel}`, params);
		},
		unborrow: (params) => {
			return put(`Pixel/UnBorrow?pixel=${params.pixel}&pixelStudent=${params.pixelStudent}`);
			// console.log("pixel", pixel);
		},
	},
	//
	monthlyWorkbook: {
		create: (params) => {
			return post("MonthlyWorkbook", params);
		},
		delete: (id) => {
			return del(`MonthlyWorkbook?id=${id}`);
		},
		list: (params) => {
			return get("MonthlyWorkbook/All", params);
		},
	},
	parentDescription: {
		disList: (params) => {
			return get("DisciplinaryReport/AllParentDescription", params);
		},
		eduList: (params) => {
			return get("EducationalDailyReport/AllParentDescription", params);
		},
	},
	// onlineLerning: {
	// 	create: (params) => {
	// 		return post("", params);
	// 	},
	// 	delete: (id) => {
	// 		return del(`Role?id=${id}`);
	// 	},
	// 	list: (params) => {
	// 		return get("Dashboard");
	// 	},
	// 	select: (params) => {
	// 		return get("Role", params);
	// 	},
	// 	update: (params, id) => {
	// 		return put(`Role?id=${id}`, params);
	// 	},
	// },
	role: {
		create: (params) => {
			return post("Role", params);
		},
		delete: (id) => {
			return del(`Role?id=${id}`);
		},
		list: (params) => {
			return get("Role/All", params);
		},
		select: (params) => {
			return get("Role", params);
		},
		update: (params, id) => {
			return put(`Role?id=${id}`, params);
		},
	},
	student: {
		create: (params) => {
			return post("Student", params);
		},
		delete: (id) => {
			return del(`Student?id=${id}`);
		},
		list: (params) => {
			return get("Student/All", params);
		},
		select: (id) => {
			return get(`Student?id=${id}`);
		},
		update: (params, id) => {
			return put(`Student?id=${id}`, params);
		},
		related: (id, searchText) => {
			if (searchText) {
				return get(`Student/Related?classId=${id}&search=${searchText}`);
			} else {
				return get(`Student/Related?classId=${id}`);
			}
		},
		updateAvatar: (params) => {
			return post("Student/ChangeAvatar", params);
		},
	},
	user: {
		create: (params) => {
			return post("User", params);
		},
		FromExcel: (classId, fileId) => {
			return post(`Student/FromExcel?classId=${classId}&fileId=${fileId}`);
		},
		delete: (id) => {
			return del(`User?id=${id}`);
		},
		list: (params) => {
			return get("User/All", params);
		},
		select: (id) => {
			return get(`User?id=${id}`);
		},
		update: (params, id) => {
			return put(`User?id=${id}`, params);
		},
		role: (params, id) => {
			return put(`User/Roles?id=${id}`, params);
		},
	},
	financial: {
		getAdmin: (params) => {
			return get("Salary/ByStudent", params);
		},
		addFinanc: (params, fileId) => {
			console.log(params, "form api func");
			console.log(fileId, "form api func");
			return post(`Salary`, params);
		},
		delete: (id) => {
			return del(`Salary?id=${id}`);
		},
		update: (params, id) => {
			return put(`Salary?id=${id}`, params);
		},
		closeProfile: (selectedStudentId) => {
			return post(`Salary/Suspend?id=${selectedStudentId}`);
		},
		openProfile: (selectedStudentId) => {
			return post(`Salary/RemoveSuspension?id=${selectedStudentId}`);
		},
		warning: (selectedStudentId) => {
			return post(`Salary/Warning?id=${selectedStudentId}`);
		},
		removewarning: (selectedStudentId) => {
			return post(`Salary/RemoveWarning?id=${selectedStudentId}`);
		},
	},
	bar: {
		educational: () => {
			return post(`Bar/Educational`);
		},
		disciplinary: () => {
			return post(`Bar/Disciplinary`);
		},
	},
	calendar: {
		list: (params) => {
			return get("Calendar", params);
		},
		getYear: () => {
			return get("Calendar/years");
		},
		createEvent: (params) => {
			return post("Calendar", params);
		},
		deleteEvent: (id) => {
			return del(`Calendar/?id=${id}`);
		},
	},
	stPanel: {
		dailyReport: (params) => {
			return get(`EducationalDailyReport/ForStudent`, params);
		},
		classAsse: (params) => {
			return get(`ClassAssessment/ForStudent`, params);
		},
		generalReports: (params) => {
			return get(`EducationalGeneralReport/ForStudent`, params);
		},
		workbook: (params) => {
			return get("MonthlyWorkbook", params);
		},
		generalExam: (params) => {
			return get("GeneralExam", params);
		},
		DisGeneralReport: (params) => {
			return get("DisciplinaryGeneralReport/ForStudent", params);
		},
		DisReport: (params) => {
			return get("DisciplinaryReport/ForStudent", params);
		},
		parentDescription: (params, id) => {
			return post(`DisciplinaryReport/ParentDescription?id=${id}`, params);
		},
		eduParentDescription: (params, id) => {
			return post(`EducationalDailyReport/ParentDescription?id=${id}`, params);
		},
		delete: (id) => {
			return del(`User?id=${id}`);
		},
		library: (params) => {
			return get("Library/ForStudent", params);
		},
		list: (params) => {
			return get("User/All", params);
		},
		select: (id) => {
			return get(`User?id=${id}`);
		},
		update: (params, id) => {
			return put(`User?id=${id}`, params);
		},
		role: (params, id) => {
			return put(`User/Roles?id=${id}`, params);
		},
		financial: (params) => {
			return get(`Salary/ForStudent`, params);
		},
		getMeeting: (params) => {
			return get(`Meeting/ForUser`, params);
		},
		getMeetingManagment: (params) => {
			return get(`ManagementMeeting/ForUser`, params);
		},
		getCours: (params) => {
			return get(`Course/ForStudent`, params);
		},
		getDocuments: () => {
			return get(`Document/ForStudent`);
		},
		postDocuments: (params) => {
			return put(`Document/Completion`, params);
		},
		reserveMeeting: (params, id) => {
			return put(`Meeting/Reserve`, params);
		},
		removeReserveMeeting: (params) => {
			return put(`Meeting/RemoveReserve`, params);
		},
		reserveMeetingManagment: (params, id) => {
			return put(`ManagementMeeting/Reserve`, params);
		},
		removeReserveMeetingManagment: (params) => {
			return put(`ManagementMeeting/RemoveReserve`, params);
		},
		teacherList: () => {
			return get(`Meeting/TeachersListForStudent`);
		},
		calendar: {
			list: (params) => {
				return get("/Calendar", params);
			},
			createEvent: (params) => {
				console.log(params);
				return post("/Calendar", params);
			},
			deleteEvent: (id) => {
				return del(`/Calendar?id=${id}`);
				return get(`Meeting/TeachersList`);
			},
		},
	},

	delCookie,
	getCookie,
	setCookie,
};
