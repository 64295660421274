import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer, Form, Input, Select } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

// import UploadImage from "components/UploadImageCropper";
import UploadImage from "components/UploadImage";

const Component = ({ data = {}, onUpdate, options: { classes = [] } }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible } = state;
	const { id, firstName, lastName } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					values.birthDate.$D = values.birthDate.$D < 10 ? `0${values.birthDate.$D}` : values.birthDate.$D;
					values.birthDate.$M = values.birthDate.$M < 9 ? `0${values.birthDate.$M + 1}` : values.birthDate.$M + 1;
					const formattedDate = `${values.birthDate.$y}-${values.birthDate.$M}-${values.birthDate.$D}`;
					values.date = formattedDate;

					setState((prevState) => ({ ...prevState, submitting: true }));
					// console.log("befor   issssssss ", values.birthDate);
					// let months = values.endDate.$M + 1;
					// if (values.birthDate) values.birthDate = `${values.endDate.$y}-${months}-${values.endDate.$D}`;

					// if (values.birthDate) values.birthDate = values.birthDate.format();
					// console.log("log date in line 78", values.birthDate);

					if (values.avatar?.id) values.avatarId = values.avatar.id;

					delete values.avatar;
					const action = hash === `#${Modals.EDIT}` ? api.student.update(values, id) : api.student.create(values);

					action
						.then(() => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true }));
					console.log(data.birthDate);
					form.setFieldsValue({
						...data,
						birthDate: dayjs(data.birthDate),
						classId: data.class?.id,
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={hash === `#${Modals.EDIT}` ? `${resources.edit} ${resources.student} "${firstName} ${lastName}"` : `${resources.add} ${resources.student}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="firstName"
					label={resources.firstName}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="lastName"
					label={resources.lastName}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="fatherName"
					label={`${resources.name} ${resources.father}`}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="phone"
					label={resources.phone}
					rules={rules.required}
				>
					<Input type="tel" />
				</Form.Item>
				<Form.Item
					name="nationalCode"
					label={resources.nationalCode}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="birthDate"
					label={resources.birthday}
					rules={rules.required}
				>
					<DatePickerJalali style={{ display: "block" }} />
				</Form.Item>
				<Form.Item
					name="classId"
					label={resources.class}
					rules={rules.required}
				>
					<Select options={classes} />
				</Form.Item>
				<Form.Item
					name="password"
					label={resources.password}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item
					name="avatar"
					label={resources.image}
				>
					<UploadImage />
				</Form.Item>
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
