import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Table, Image } from "antd";
import {
  DatePicker as DatePickerJalali,
  Calendar as CalendarJalali,
  useJalaliLocaleListener,
} from "antd-jalali";
import dayjs from "dayjs";

import { GetUrlParams, AddNotification } from "scripts/helpers";
import { ENV } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";

const Component = ({ permissions, setRoute }) => {
  useJalaliLocaleListener();
  dayjs.calendar("jalali");

  const initialState = {
    assessments: [],
    classes: [],
    data: [],
    regulations: [],
    students: [],
    pagination: {},
  };
  const tableColumns = [
    {
      title: resources.row,
      dataIndex: "index",
      className: "center-cell",
      width: "60px",
      render: (value, record, index) => index + 1,
    },
		{
			title: resources.description,
			dataIndex: "description",
			className: "center-cell desc-cell ltr-cell",
			width: "250px",
			render: (value) => {
				return (
					<div>
						<span>{value}</span>
					</div>
				);
			},
		},
    {
      title: `${resources.title}`,
      dataIndex: "title",
      className: "center-cell",
      render: (value) => {
        return <span className="name">{value}</span>;
      },
    },
    {
      title: `${resources.student}`,
      dataIndex: "student",
      className: "center-cell",
      render: (value) => {
        return <span className="name">{value}</span>;
      },
    },

    {
      title: `${resources.image}`,
      dataIndex: "fileUrl",
      className: "center-cell",
      render: (value) => {
        return !value ? (
          "-"
        ) : (
          <Image
            src={`${ENV.basePath}${value}`}
            // shape="square"
            width={40}
            height={40}
          />
        );
      },
    },
  ];
  const [state, setState] = useState(initialState);
  const { data, loading } = state;
  const { pathname, search } = useLocation();
  const [modal, contextHolder] = Modal.useModal();

  const fetchData = () => {
    AddNotification();

    const params = {
      page: search || 1,
      pageSize: 10,
      Paging: true,
    };
    api.pixel
      .forStudent(params)
      .then((res) => {
        setState((prev) => ({
          ...prev,
          data: res,
          loading: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      });
    if (params.student) {
      setState((prevState) => ({ ...prevState, loading: true }));
    } else {
      setState((prevState) => ({ ...prevState, data: [] }));
    }
  };

  const componentDidUpdate = () => {
    fetchData();
  };

  const componentDidMount = () => {
    const params = GetUrlParams(search);

    setRoute();
    fetchData();
    api.pixel
      .forStudent(params)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          data: res.list,
          loading: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  };

  useEffect(componentDidUpdate, [search]);
  useEffect(componentDidMount, []);
  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={data}
        loading={loading}
        pagination={false}
        scroll={{ x: 930 }}
        size="small"
        rowKey="id"
        // expandable={{ expandedRowRender: (record) => `${resources.description}: ${record.description}` }}
      />
      {contextHolder}
    </>
  );
};

export default Component;
